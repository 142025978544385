<template>
  <div class="step-container">
    <div class="text-center">
      <div class="body4-bold primary margin-bottom-12">2/3단계</div>
      <div class="h6 main">내 플랫폼 서비스가 온라인에서 작동하기 위해 호스팅이 필요해요.</div>
      <select-hosting :value.sync="order.hosting"
                        @update:value="val=>order.hosting_id=val.id"></select-hosting>

      <div class="flex-center">
        <div class="flex-align" style="max-width:450px;width:100%;margin-bottom:40px">
          <button class="button is-primary body2-medium" @click="clickNext"
                  style="width:100%;height:52px;margin-top:40px;">다음
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import SelectHosting from "../../component/SelectHosting";

  export default {
    name: "ServiceCreateStep4",
    components: {SelectHosting},
    props: {
      order: {
        type: Object
      }
    },
    created() {
    },
    data() {
      return {
        product: undefined,
        optionArr1: [],
        optionArr2: [],
        requiredOptionsValue: [],
        order_product: undefined,
        selectorStocks: undefined,
      }
    },
    computed: {
      totalPrice() {
        let price = 0;
        if (this.order.hosting) {
          price += this.order.hosting.price
        }
        price += this.order.subscribe_price
        price += price / 10;
        return price;
      }
    },
    methods: {
      init() {
        this.getData();
      },
      getData() {
        this.$axios.get(`public/launchpack/v1/theme/174`).then(async res => {
          if (res.status === 200) {
            this.product = res.data;
            this.order_product = {
              product_id: this.product.id,
              quantity: 1,
              discount_price: 0,
              product_price: 0,
              total_price: 0,
              product: this.cloneItem(this.product),
              order_product_option: [],
              required_option_price: 0,
              add_option_price: 0
            }
            this.$axios.get('public/launchpack/service_plan').then(plan_res => {
              res.data.selectors[0].options.forEach(item => {
                let plan = plan_res.data.filter(plan => {
                  return plan.membership === item.name;
                })[0];
                const tb = {
                  user: {index: 1, kor: '회원가입 수', unit: '명', tag: ''},
                  product: {index: 2, kor: '상품 등록 수', unit: '건', tag: ''},
                  category: {index: 3, kor: '상품 카테고리 수', unit: '개', tag: ''},
                  partner: {index: 4, kor: '파트너 등록 수', unit: '건', tag: ''},
                  admin_account: {index: 5, kor: '관리자 계정 갯수', unit: '명', tag: ''},
                  front: {index: 6, kor: '프론트 서비스 제공 범위', unit: '', tag: ''},
                  template: {index: 7, kor: '블럭템플릿 무제한 이용권', unit: '', tag: '디자인 수정 시 런치팩의 디자인 템플릿을 무제한으로 이용할 수 있습니다.'}
                };

                item.plan = Object.keys(plan.usage).map(key => {
                  return {
                    index: tb[key].index,
                    name: tb[key].kor,
                    text: plan.usage[key] + tb[key].unit,
                    tag: tb[key].tag
                  }
                }).sort((a, b) => {
                  return a.index - b.index
                });
                this.getSelectorStocks();
              });
            });
          }
        });
      },
      getSelectorStocks() {
        let url = `public/product/${this.product.id}/selector_stocks`
        this.$axios.post(url).then(res => {
          this.selectorStocks = res.data.stocks;
          this.initOptions();
        })
      },
      initOptions() {
        // 옵션 초기화
        let selectors = this.cloneItem(this.product.selectors)
        this.optionArr1 = selectors.filter(item => {
          item.disabled = true;
          item.expand = false;
          return item.option_type === 0;
        });

        if (this.optionArr1.length > 0) {
          this.optionArr1[0].disabled = false;

          // 기본 선택 표시
          let options = this.optionArr1[0].options;
          if (options.length > 0) {
            options[0].selected = true;
            this.selectOption1(options[0], this.optionArr1[0]);
            if (this.optionArr1.length > 1) this.optionArr1[1].disabled = false;
          }
        }
        this.optionArr2 = selectors.filter(item => {
          return item.option_type === 1;
        })
      },
      // 필수옵션 선택
      selectOption1(option, selector) {
        let option_id = option.id;
        if (!option_id) return;

        // 첫번째 옵션 선택할 경우 2번째부터는 선택해제
        if (this.optionArr1[0].id === selector.id) {
          this.order.subscribe_price = 0;
          this.requiredOptionsValue = [];
          this.initOrderProductOption();
          this.optionArr1.slice(1, this.optionArr1.length).forEach(item => {
            item.options.forEach(op => {
              op.selected = false;
            })
          })
          if (this.optionArr1.length >= 3) {
            this.optionArr1.slice(2, this.optionArr1.length).forEach(item => {
              item.disabled = true;
            })
          }
        }
        // 옵션 선택시 false로 초기화 후 옵션 선택
        selector.options.forEach(op => {
          op.selected = false;
        })
        option.selected = true;

        // 필수 선택값 조합 체크 후 requiredOptionsValue에 추가
        let selector_item = this.findItem(this.requiredOptionsValue, 'selector_id', selector.id);
        let selectorIndex = this.optionArr1.findIndex(item => item.id === selector.id)

        // 옵션 선택시 다음 옵션 노출
        if (selectorIndex + 1 < this.optionArr1.length) {
          this.optionArr1[selectorIndex + 1].disabled = false;
        }

        if (!selector_item) {
          this.requiredOptionsValue.push({
            selector_id: selector.id,
            option_id: option_id
          });
        } else {
          selector_item.option_id = option_id
        }
        // 마지막 필수 옵션 선택한 경우
        if (selectorIndex + 1 === this.optionArr1.length) {
          let selectedOptions = [];
          this.requiredOptionsValue.forEach(item => {
            selectedOptions.push(item.option_id);
          })
          selectedOptions.sort(function (a, b) {
            return a - b;
          });
          // 주문 상품 추가 (order_product_option)
          for (let stock of this.selectorStocks) {
            stock.options.sort(function (a, b) {
              return a - b;
            });
            // console.log(JSON.stringify(stock.options), '비교할 값')
            // console.log(JSON.stringify(selectedOptions), '선택된 값')
            // 재고 배열에서 선택된 옵션 조합들을 찾으면 재고 id를 order_product_option에 추가
            if (JSON.stringify(stock.options) === JSON.stringify(selectedOptions)) {
              this.initOrderProductOption();
              this.order_product.order_product_option.push({
                option_id: stock.id,
                quantity: 1,
                option_type: stock.option_type
              });
              break;
            }
          }
        } else {
          this.requiredOptionsValue = this.requiredOptionsValue.slice(0, selectorIndex + 1)
        }
        this.calcTotalPrice();
      },
      initOrderProductOption() {
        let opoIndex = this.order_product.order_product_option.findIndex(opo => opo.option_type === 0)
        if (opoIndex > -1) this.order_product.order_product_option.remove(opoIndex);
      },
      calcTotalPrice() {
        // let total = 0;
        let requiredOptionPrice = 0;
        let addOptionPrice = 0;
        // 상품 옵션일 경우
        if (this.product.enable_option) {
          // required_option_price = 필수옵션
          // add_option_price = 추가옵션
          // total_price = 필수옵션 + 추가옵션
          this.order_product.order_product_option.forEach(item => {
            let op = this.findItem(this.selectorStocks, 'id', item.option_id);
            if (op !== undefined) {
              if (item.option_type === 0) {
                requiredOptionPrice += op.price * parseInt(item.quantity)
              } else if (item.option_type === 1) {
                addOptionPrice += op.price * parseInt(item.quantity)
              }
            }
          })
        }
        // this.order_product.required_option_price = requiredOptionPrice;
        // this.order_product.add_option_price = addOptionPrice;

        this.order_product.product_price = requiredOptionPrice + addOptionPrice;
        this.order_product.total_price = this.order_product.product_price;
        this.order.subscribe_price = this.order_product.product_price;
      },
      originPrice(option) {
        return option.price / (1 - option.discount_rate / 100)
      },
      clickNext() {
        this.order.total_price = this.totalPrice;
        this.order.subscribe_price = this.totalPrice;
        this.$emit('next');
        return;
      }
    }
  }
</script>
<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  @media (min-width: 1025px)
    .v-mobile
      display none

    .plan-select-wrapper
      display grid
      grid-gap 24px
      grid-template-columns repeat(3, 1fr)
    .plan-item
      padding-bottom 12px

    .plan-item:first-child
      padding-top 0
      border-top 0
  @media (max-width: 1024px)
    .step-title
      white-space pre-line
    .step-container
      height 100%
      padding-top 40px
      padding-left 16px
      padding-right 16px

    .plan-select-wrapper
      margin-bottom 10px

    .plan-sale
      padding 12px 0
      text-align center
      border-top 1px solid $gray2
      border-bottom 1px solid $gray2
      margin 12px 0

      img
        vertical-align sub
    .plan-item
      border 0
      padding 10px 0


  .option-st
    border 1px solid $gray1
    border-radius 8px
    margin-bottom 20px


  .tag-primary
    color $primary
    background-color $primary-light2
    border-radius 4px
    font-size 13px
    padding 2px 8px

  .box-step
    max-width 450px
    margin 0 auto
    padding 32px
    background-color $gray4
    border-radius 8px
    text-align left

  .box-info
    margin-top 4px
    background-color $gray3
    width 100%
    padding 10px
    border-radius 8px
    display flex
    align-items center
    justify-content center

    img
      width 16px
      height 16px
      margin-right 4px


  .pc
    .option-st
      padding 20px 24px

    .option-st.selected
      padding 19px 23px

  .mobile
    .option-st
      padding 16px 20px

    .option-st.selected
      padding 15px 19px

  .disabled
    background-color #eee

  .selected
    border 2px solid $primary !important

  .step4-content-wrapper
    background-image url(/static/img/bg_info.svg)
    background-repeat no-repeat
    background-size cover
    background-position center
    height 381px
    padding 40px 50px
    text-align left
    display flex
    align-items center
    justify-content space-between

  .title-s-box
    padding-bottom 12px
    border-bottom 1px solid $gray2

  .hosting-box
    width 100%
    padding-top 60px
    border-top 1px solid $gray
</style>
